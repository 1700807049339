<template>
  <nav
    class="PublicSiteNavBar z-[1000] top-0 sticky border-b-2 border-primary-light bg-white p-1"
    :class="{ '!bg-danger': userSwitcher.switchedToMate }"
  >
    <ul
      class="TopRow flex flex-row justify-between max-w-7xl mx-auto gap-3 items-center max-lg:hidden"
    >
      <NuxtLink to="/">
        <JoszakiLogo />
      </NuxtLink>
      <NavBarItem
        v-if="userSwitcher.switchedToMate"
        :text="$t('navbar.switchBackToAdmin')"
        type="primary"
        @click="switchBackToAdmin"
      />
      <AutocompleteSearch class="flex-1 max-lg:hidden" />
      <NavBarItem
        v-for="({ click, ...props }) in topItems"
        :key="props.id"
        class="max-lg:hidden"
        v-bind="props"
        v-on="click ? { click } : {}"
      />
    </ul>
    <ul
      v-if="bottomItems.length"
      class="BottomRow flex flex-row justify-center max-w-7xl mx-auto gap-3 max-lg:hidden"
    >
      <NavBarItem
        v-for="({ click, ...props }, index) in bottomItems"
        :key="index"
        v-bind="props"
        v-on="click ? { click } : {}"
      />
    </ul>
    <ul class="MobileNavBar lg:hidden flex flex-row gap-2">
      <NuxtLink to="/">
        <JoszakiLogo :hide-label="isSuperprofikCz || showMobileSearch" />
      </NuxtLink>
      <div class="flex flex-1 flex-row gap-2 justify-end">
        <NavBarItem
          v-if="userSwitcher.switchedToMate"
          :text="$t('navbar.switchBackToAdmin')"
          type="primary"
          @click="switchBackToAdmin"
        />
        <template v-if="!userSwitcher.switchedToMate">
          <AutocompleteSearch
            v-show="showMobileSearch"
            class="flex-1"
            @focus="showMobileMenu = false"
          />
          <NavBarItem
            type="primary"
            :icon="showMobileSearch ? 'angle-left' : 'search'"
            @click="showMobileSearch = !showMobileSearch"
          />
          <NavBarItem
            v-show="!showMobileSearch && !showMobileMenu"
            :text="$t('navbar.tenderButton')"
            type="primary"
            @click="requestTender({ source: 'header' })"
          />
        </template>
        <NavBarItem
          class="self-center"
          icon="bars"
          @click="showMobileMenu = !showMobileMenu"
        />
      </div>
    </ul>
    <ul
      :class="{ 'max-lg:hidden': !showMobileMenu }"
      class="MobileNavBarDropdown lg:hidden flex-col flex-1 gap-2 absolute w-full bg-white border-b-2 left-0 top-full border-primary-light px-2 overflow-scroll max-h-[75vh]"
    >
      <NavBarItem
        v-for="({ click, ...props }, index) in topItems"
        :key="`top-${index}`"
        v-bind="props"
        v-on="click ? { click } : {}"
      />
      <NavBarItem
        v-for="({ click, ...props }, index) in bottomItems"
        :key="`bottom-${index}`"
        v-bind="props"
        v-on="click ? { click } : {}"
      />
    </ul>
  </nav>
</template>

<script>
import { ref, computed } from "vue";
import { useContext } from "@nuxtjs/composition-api";
import NavBarItem from "./NavBarItem.vue";
import JoszakiLogo from "@/components/_refactored/img/JoszakiLogo.vue";
import AutocompleteSearch from "~/components/_refactored/header/AutocompleteSearch";
import { useRequestTender } from "~/composables/requestTender";
import { useAdminUserSwitcher } from "~/components/_refactored/admin";
import { useBrand } from "~/composables/useBrand";
import { useUserMenu } from "~/components/_refactored/header/useUserMenu";
import { usePopularProfessions } from "~/composables/usePopularProfessions";
import { usePopularProfessionTasks } from "~/composables/usePopularProfessionTasks";

export default {
  components: {
    JoszakiLogo,
    NavBarItem,
    AutocompleteSearch,
  },
  setup() {
    const { isJoszaki, isSuperprofikCz } = useBrand();
    const showMobileMenu = ref(false);
    const showMobileSearch = ref(false);
    const { i18n, localePath } = useContext();
    const { userMenu } = useUserMenu();
    const userSwitcher = useAdminUserSwitcher();

    const { requestTender } = useRequestTender();

    const { professionMenuItems } = usePopularProfessions();
    const { professionTaskMenuItems } = usePopularProfessionTasks();

    const topItems = computed(() => {
      const items = [
        {
          id: "request-tender",
          text: i18n.t("navbar.tenderButton"),
          click: () => requestTender({ source: "header" }),
          type: "primary",
        },
        {
          id: "professionals",
          text: i18n.t("navbar.professions.label"),
          icon: "briefcase",
          items: professionMenuItems.value,
        },
      ];

      if (isJoszaki) {
        items.push(
          ...[
            {
              id: "prices",
              text: i18n.t("navbar.professionTasks.label"),
              icon: "dollar-sign",
              items: professionTaskMenuItems.value,
            },
            {
              id: "questions",
              text: i18n.t("navbar.questionsPage"),
              to: localePath({ name: "questionsPage" }),
              icon: "question-circle",
            },
          ]
        );
      }

      items.push(...userMenu.value);
      return items;
    });

    // todo: later on, the navbar will be split into two rows, but for now, it stays empty
    const bottomItems = computed(() => {
      return [];
    });

    const switchBackToAdmin = async () => {
      await userSwitcher.switchBackToAdmin();
    };

    return {
      ...useRequestTender,
      showMobileMenu,
      showMobileSearch,
      topItems,
      bottomItems,
      userSwitcher,
      isSuperprofikCz,
      switchBackToAdmin,
      requestTender,
    };
  },
};
</script>
